import React, { FC } from 'react';

import { IMediaFile } from 'interfaces/media';

import GatsbyPicture from '../../GatsbyPicture/GatsbyPicture';

interface IImage {
  className?: string;
  files?: IMediaFile[];
  src: string;
  alt?: string;
  title?: string;
}

const Image: FC<IImage> = ({
  files, src, alt, className, title, ...res
}) => {
  const apiUrl = process.env.API_URL || 'http://localhost:1337';
  const currentFile = files?.find(({ url }) => url === `${apiUrl}${src}`);

  if (!currentFile) {
    return (
      <GatsbyPicture
        title={title}
        className={className}
        {...currentFile}
        {...res}
      />
    );
  }

  return (
    <GatsbyPicture
      title={title}
      className={className}
      {...currentFile}
      {...res}
    />
  );
};

export default Image;
