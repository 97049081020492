import styled from '@emotion/styled';

import { Layout } from 'UI';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K } from 'constants/sizes';
import UISocialLinks from './SocialLinks/SocialLinks';
import UIPolicyLinks from './PolicyLinks/PolicyLinks';

export const Container = styled.footer`
  margin-top: 7em;
  padding-bottom: 3em;
  color: var(--black-opacity);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-top: 0;
  }
`;

export const Wrapper = styled(Layout)`
  row-gap: 5em;
  align-items: center;
  grid-template-areas:
    'a a a a a . . . . .'
    'b b b b b s s c c c'
    'b b b b b s s . . .';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    row-gap: 30px;
    grid-template-areas:
      'a a a a a a'
      'b b b b b b'
      'c c c c c c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 20px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c';
  }

  @media (min-width: ${MIN_2K}px) {
    row-gap: 4em;
  }
`;

export const Legal = styled.small`
  grid-area: b;
  white-space: pre-wrap;
`;

export const SocialLinks = styled(UISocialLinks)`
  grid-area: a;
`;

export const Space = styled.div`
  grid-area: s;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: none;
  }
`;

export const PolicyLinks = styled(UIPolicyLinks)`
  grid-area: c;
`;
