import React, { FC } from 'react';

import { IIcon } from './interfaces';

const SmallLogoIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M8.02026 15.418L0 18L8.02026 0V15.418Z" fill="#FF1E31" />
    <path d="M10.4868 15.418L18.5058 18L10.4868 0V15.418Z" fill="#FF1E31" />
  </svg>
);

export default SmallLogoIcon;
