import React, { FC } from 'react';

import { IIcon } from './interfaces';

const DownloadIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    version="1.1"
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="m1 5.9 2.88 3.1h0.24l2.88-3.1v-1.4l-2.52 2.7v-7.2h-0.96v7.2l-2.52-2.7z" />
    <path d="m0 11h8v1h-8z" />
  </svg>
);

export default DownloadIcon;
