import React, { FC } from 'react';

import { IIcon } from './interfaces';

const TwitterIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="m10.14 2.988c0.4466-0.04818 0.8802-0.1719 1.279-0.3438-0.3021 0.4401-0.681 0.832-1.113 1.148 0.0065 0.09636 0.0065 0.1927 0.0065 0.2891 0 2.936-2.234 6.319-6.318 6.319-1.258 0-2.426-0.3646-3.41-0.9961 0.1784 0.0208 0.3503 0.0273 0.5365 0.0273 1.038 0 1.993-0.3515 2.757-0.9492-0.9753-0.0208-1.794-0.6602-2.076-1.54 0.138 0.0196 0.2747 0.0339 0.4193 0.0339 0.1973-6e-4 0.3937-0.0259 0.5846-0.0755-1.017-0.2058-1.78-1.1-1.78-2.18v-0.02735c0.3077 0.1719 0.6517 0.2687 1.004 0.2826-0.5976-0.3997-0.9896-1.079-0.9896-1.85-0.00128-0.394 0.103-0.7811 0.3021-1.121 1.092 1.348 2.736 2.228 4.578 2.324-0.03386-0.1654-0.05469-0.3372-0.05469-0.5091 0-1.224 0.9896-2.221 2.22-2.221 0.3042-6.7e-4 0.6052 0.0613 0.8843 0.182 0.2791 0.1208 0.5304 0.2977 0.7381 0.5198 0.4971-0.09611 0.9738-0.2776 1.409-0.5364-0.1657 0.5133-0.5129 0.9485-0.9766 1.224z" />
  </svg>
);

export default TwitterIcon;
