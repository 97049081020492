import React, { FC, ReactNode } from 'react';

import {
  Wrapper,
  Preview,
  Icon,
  Date,
  Title,
  SourceTitle,
} from './NewsCard.styled';

interface INewsCard {
  children?: ReactNode;
  className?: string;
  link?: string;
  icon?: ReactNode;
  title: string;
  date?: string;
  target?: string;
  withSource?: boolean;
}

const NewsCard: FC<INewsCard> = ({
  className,
  children,
  link,
  icon,
  title,
  date,
  target = '_blank',
  withSource = false,
}) => {
  const getUrlHashe = () => {
    if (typeof window !== 'undefined' && link) {
      try {
        const url = new URL(link);
        return url?.hostname?.replace(/^www\./, '') || '';
      } catch (error) {
        return link;
      }
    }
    return link;
  };

  return (
    <Wrapper className={className} href={link} target={target} rel="noreferrer">
      <Preview>
        {children}
      </Preview>
      {icon && (
        <Icon>{icon}</Icon>
      )}
      <Title>
        {title}
      </Title>
      {date && (
        <Date>
          {withSource && (
            <SourceTitle href={link} target={target} rel="noreferrer">
              {getUrlHashe()}
            </SourceTitle>
          )}
          <span>{date}</span>
        </Date>
      )}
    </Wrapper>
  );
};

export default NewsCard;
