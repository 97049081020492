import React, {
  FC,
  ReactNode,
  forwardRef,
  useRef,
  useEffect,
  Ref,
  createRef,
  useImperativeHandle,
} from 'react';

import SliderWrapper from './SliderWrapper/SliderWrapper';
import SliderMenu from './SliderMenu/SliderMenu';

import setActiveMenu from './Utils/appear';
import { scrollToSlide, scrollPointer } from './Utils/scroll';

interface ISliderRef {
  setMenu?: (index: number) => void;
  setNewSlide?: (index: number, smooth?: boolean) => void;
}

interface IScrollableSlider {
  items: string[];
  children: ReactNode;
  onChangeSlide: (slide: number) => void;
  appear?: 'scaleVisible' | '';
  onChangeVisibility?: (visibility: boolean) => void;
  onRelativeScroll?: (percent: number) => void;
  ref: Ref<ISliderRef>;
  onMenuItemHover?: (index: number) => void;
  noMenu?: boolean;
  className?: string;
  menuRef?: Ref<HTMLDivElement>;
}

const ScrollableSlider: FC<IScrollableSlider> = forwardRef(
  (
    {
      items,
      children,
      onChangeSlide,
      onMenuItemHover = () => {},
      appear = '',
      onChangeVisibility = () => {},
      onRelativeScroll = () => {},
      noMenu = false,
      className = '',
      menuRef,
    },
    ref,
  ) => {
    let activeSlide = 0;

    const containerRef = createRef<HTMLDivElement>();

    /**
     * Menu refs
     */
    const menuRefs = useRef(items.map(() => createRef<HTMLDivElement>()));
    const positionRef = useRef<HTMLDivElement>();

    const changeSlide = (index: number) => {
      activeSlide = index;

      setActiveMenu({ refs: menuRefs, index });

      onChangeSlide(index);
    };

    const handleScroll = (scroll: number) => {
      onRelativeScroll(scroll);
      scrollPointer({
        ref: positionRef,
        position: scroll,
        slide: activeSlide,
        menuRefs: menuRefs.current,
      });
    };

    const setSlide = (index: number, smooth?: boolean) => {
      activeSlide = index;

      scrollToSlide({ containerRef, slide: index, smooth });
      onChangeSlide(index);
      setActiveMenu({ refs: menuRefs, index });
    };

    /**
     * For call functions from parent
     */
    useImperativeHandle(ref, () => ({
      setNewSlide(index: number, smooth?: boolean) {
        setSlide(index === items.length ? index + 1 : index, smooth);
      },
      setMenu(index: number) {
        setActiveMenu({ refs: menuRefs, index });
      },
    }));

    useEffect(() => {
      setActiveMenu({ refs: menuRefs, index: activeSlide });
      changeSlide(activeSlide);
    }, []);

    onChangeSlide(activeSlide);

    return (
      <div ref={containerRef} className={className}>
        <SliderWrapper
          count={items.length}
          onChangeSlide={changeSlide}
          onScroll={handleScroll}
          setVisibility={onChangeVisibility}
          appear={appear}
        >
          {!noMenu && (
            <SliderMenu
              ref={menuRef}
              refs={menuRefs}
              items={items}
              positionRef={positionRef}
              onChange={setSlide}
              onItemHover={onMenuItemHover}
            />
          )}
          {children}
        </SliderWrapper>
      </div>
    );
  },
);

export default ScrollableSlider;
