import React, { FC, ReactNode } from 'react';

import { Span } from './MoreText.styled';

interface IMoreText {
  className?: string;
  active?: boolean;
  children: ReactNode;
}

const MoreText: FC<IMoreText> = ({
  className = '', active = false, children, ...res
}) => (
  <Span className={`${className} ${active ? 'active' : ''}`} {...res}>
    {children}
  </Span>
);

export default MoreText;
