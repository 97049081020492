import styled from '@emotion/styled';

import { ButtonLink } from '../Button/Button';

const Button = styled(ButtonLink)`
  background: #eaeaea;
  color: #000;

  :hover {
    color: #fff;
    background: #000;
    fill: #fff;
  }
`;

export default Button;
