import React, {
  useEffect, FC, ReactNode, useRef,
} from 'react';

import throttle from 'utils/throttle';
import ScaleVisible from '../../ScaleVisible/ScaleVisible';
import { Container, SlidesWrapper, Slides } from './styled';

import FullHeight from '../../FullHeight/FullHeight';

interface ISliderWrapper {
  count: number;
  children: ReactNode;
  onChangeSlide?: (slide: number) => void;
  onScroll?: (scroll: number) => void;
  setVisibility: (isVisible: boolean) => void;
  appear?: 'scaleVisible' | '';
}

const SliderWrapper: FC<ISliderWrapper> = ({
  count,
  children,
  onChangeSlide = () => {},
  onScroll = () => {},
  appear = '',
  setVisibility,
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  let activeSlide = 0;

  const setActiveSlide = (index = 0) => {
    if (index === activeSlide) return;
    activeSlide = index;
    onChangeSlide(index);
  };

  const handleScroll = () => {
    const wrapperHeight = wrapperRef.current?.offsetHeight;
    const scrollTop = -containerRef.current?.getBoundingClientRect().top;

    if (!wrapperHeight || scrollTop < 0) return;

    const position = Math.floor(scrollTop / wrapperHeight) >= count
      ? count - 1
      : Math.floor(scrollTop / wrapperHeight);

    setActiveSlide(Math.abs(position));

    const relativeScroll = (scrollTop - wrapperHeight * position) / wrapperHeight;

    onScroll(relativeScroll);
  };

  const startScrollHandler = () => {
    throttle(() => {
      requestAnimationFrame(handleScroll);
    }, 30)();
  };

  const handler = (entries: IntersectionObserverEntry[]) => {
    const { intersectionRatio, isIntersecting } = entries[0];

    if (isIntersecting && intersectionRatio > 0.5) {
      setVisibility(true);
      document.removeEventListener('scroll', startScrollHandler, true);
      document.addEventListener('scroll', startScrollHandler, true);
    } else if (isIntersecting && intersectionRatio <= 0.5) {
      setVisibility(false);
      handleScroll();
    } else {
      setVisibility(false);
      document.removeEventListener('scroll', startScrollHandler, true);
    }
  };

  const Wrapper = ({ children: child, ...props }) => (appear === 'scaleVisible' ? (
    <ScaleVisible {...props}>{child}</ScaleVisible>
  ) : (
    <FullHeight {...props}>{child}</FullHeight>
  ));

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined' && wrapperRef.current) {
      const options = {
        threshold: [0, 0.9],
      };
      const observer = new IntersectionObserver(handler, options);
      observer.observe(wrapperRef.current);
    }

    document.addEventListener('scroll', startScrollHandler, true);

    return () => {
      document.removeEventListener('scroll', startScrollHandler);
    };
  }, []);

  return (
    <Container style={{ height: `${(count + 1) * 100}vh` }} ref={containerRef}>
      <SlidesWrapper ref={wrapperRef}>
        <Wrapper>
          <Slides>{children}</Slides>
        </Wrapper>
      </SlidesWrapper>
    </Container>
  );
};

export default SliderWrapper;
