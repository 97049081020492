import styled from '@emotion/styled';
import { MAX_MOB_WIDTH } from 'constants/sizes';
import Markdown from 'markdown-to-jsx';

export const Wrapper = styled.div`
  position: absolute;
  max-width: calc(100vw - 4em);
  width: 30em;
  right: 0;
  bottom: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
  padding-bottom: 1em;

  :hover {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const Text = styled(Markdown)`
  font-size: small;
  color: var(--middle-gray);
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
  background: var(--white);
  padding: 1.4em 1.7em;
  border: 1px solid var(--border-color);
  box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.1);

  a {
    color: var(--black);

    :hover {
      text-decoration: underline;
    }
  }
`;

interface ITooltipWrapper {
  required: boolean;
}

export const TooltipWrapper = styled.div<ITooltipWrapper>`
  position: relative;

  @media screen and (max-width: ${MAX_MOB_WIDTH}px) {
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  :hover {
    ${Wrapper} {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }

    @media screen and (max-width: ${MAX_MOB_WIDTH}px) {
      ::before {
        transition: 0.01s;
        width: 0px;
      }
    }
  }
`;
