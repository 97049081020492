import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { MIN_4K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';

import UIImage from './Image/Image';
import MiddleText from '../MiddleText/MiddleText';

export const Header = styled(MiddleText)``;

export const HeaderOne = styled(Header)``;

export const HeaderTwo = styled(Header)`
  margin-top: 1em;
`;

export const Title = styled.h2`
  grid-column: 1/5;
  margin-bottom: 0.5em;
`;

export const Description = styled(Markdown)`
  grid-column: 3/-1;
  padding-bottom: 5rem;

  + ${HeaderOne} {
    margin-top: 1em;
  }
`;

export const Image = styled(UIImage)`
  grid-column: 1/-1;
`;

export const Container = styled.article`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 20px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    gap: 16px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
  }

  @media (min-width: ${MIN_4K}px) {
    gap: 40px;
  }

  p,
  ul,
  li {
    grid-column: 3/-1;

    + ${HeaderOne} {
      margin-top: 1rem;

      + * {
        margin-top: 1rem;
      }
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      grid-column: 1/-1;
    }
  }

  a {
    color: var(--red);
  }

  li {
    ::before {
      content: '•';
      padding-right: 0.4rem;
    }
  }

  ${HeaderOne} {
    grid-column: 1/3;
    grid-row: span 2;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      grid-column: 1/-1;
    }
  }

  ${Image} {
    grid-column: 1/-1;
    margin: 0 -35%;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      margin: 0;
    }
  }
`;
