/* eslint-disable */
type ThrottledFunction<T extends (...args: any) => any> = (...args: Parameters<T>) => ReturnType<T>;

function throttle<T extends (...args: any) => any>(func: T, time = 15): ThrottledFunction<T> {
  let inThrottle: boolean;
  let lastResult: ReturnType<T>;

  return function (this: any): ReturnType<T> {
    const args = arguments;
    const context = this;

    if (!inThrottle) {
      inThrottle = true;

      setTimeout(() => (inThrottle = false), time);

      lastResult = func.apply(context, args);
    }

    return lastResult;
  };
};

export default throttle;
