import styled from '@emotion/styled';

export const Span = styled.span`
  cursor: pointer;

  ::after {
    content: '';
    font-weight: 600;
    margin-left: 5px;
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    transform: translateY(0.28em) translateX(0.4em) rotate(90deg);
    background-image: url('/svg/learn-more-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    will-change: margin-left;
    transition: 0.2s ease-in-out;
  }

  &.active {
    ::after {
      transform: translateY(-0.0em) translateX(0.4em) rotate(-90deg);
    }
  }
`;
