import React, { FC } from 'react';

import { Link } from 'gatsby';

import { ILink } from '../type';
import { Container, LinkWrapper } from './PolicyLinks.styled';

interface IPolicyLink {
  className?: string;
  links: ILink[];
}

const PolicyLinks: FC<IPolicyLink> = ({ className = '', links, ...props }) => (
  <Container className={className} {...props}>
    {links?.map(({ title, link, id }) => (
      <LinkWrapper key={`footer-link_${id}`}>
        <Link to={link}>{title}</Link>
      </LinkWrapper>
    ))}
  </Container>
);

export default PolicyLinks;
