import React, { useRef, useEffect, forwardRef } from 'react';

import throttle from 'utils/throttle';

import { VideoElement } from './styled';

interface IIntersectionOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number[] | number;
}

interface IMedia {
  src: string;
  srcSet?: {
    1080?: string;
    720?: string;
    540?: string;
  };
  className?: string;
  preload?: string;
  loop?: boolean;
  playsInline?: boolean;
  muted?: boolean;
  poster?: string;
  type?: string;

  playOnVisible?: boolean;
  intersectionOptions?: IIntersectionOptions;
}

const Video = forwardRef<HTMLVideoElement, IMedia>(
  (
    {
      src,
      srcSet = null,
      className = '',
      preload = 'auto',
      loop = false,
      playsInline = true,
      muted = true,
      poster = '',
      type = '',
      playOnVisible = true,
      intersectionOptions = null,
      ...props
    },
    ref,
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    let realWindowSize = 0;
    const voidFunction = () => {};

    const setVideoSize = async () => {
      if (!videoRef.current || (realWindowSize === window.innerWidth && videoRef.current.src)) {
        return;
      }

      realWindowSize = window.innerWidth;

      let activeSrc = '';

      if (realWindowSize > 2048) {
        activeSrc = src;
      } else if (realWindowSize > 1400) {
        activeSrc = srcSet['1080'];
      } else if (realWindowSize > 900) {
        activeSrc = srcSet['720'];
      } else {
        activeSrc = srcSet['540'];
      }

      if (!videoRef.current?.src.includes(activeSrc)) {
        const { paused } = videoRef.current;
        videoRef.current.src = activeSrc;

        if (!paused) {
          try {
            await videoRef.current?.play();
          } catch (error) {
            videoRef.current?.pause();
          }
        }
      }
    };

    const playVideo = async (isIntersecting: boolean) => {
      if (!videoRef.current) return;

      if (isIntersecting) {
        try {
          await videoRef.current?.play();
        } catch (error) {
          videoRef.current?.pause();
        }
      } else {
        videoRef.current?.pause();
      }
    };

    const handlerObserver = (entries: IntersectionObserverEntry[]) => {
      const { isIntersecting } = entries[0];

      if (srcSet) {
        setVideoSize().then(voidFunction, voidFunction);
      }
      // eslint-disable-next-line no-void
      void playVideo(isIntersecting);
    };

    const handleResize = () => {
      throttle(() => {
        setVideoSize().then(voidFunction, voidFunction);
      }, 400)();
    };

    useEffect(() => {
      if (playOnVisible) {
        const options = intersectionOptions || {
          threshold: 0.0,
          rootMargin: '100% 0px 0px',
        };

        const observer = new IntersectionObserver(handlerObserver, options);

        observer.observe(videoRef.current);
      }

      if (srcSet !== null) {
        window.addEventListener('resize', handleResize, true);
        setVideoSize().then(voidFunction, voidFunction);
      }

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
      <VideoElement
        preload={preload}
        loop={loop}
        playsInline={playsInline}
        muted={muted}
        poster={poster}
        className={className}
        src={src}
        type={type}
        ref={(node) => {
          videoRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
        }}
        {...props}
      />
    );
  },
);

export default Video;
