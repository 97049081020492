import React, {
  Ref, forwardRef, ReactChild, MutableRefObject, MouseEvent,
} from 'react';

import {
  Container, SliderMenuLink, ScrollPointer, Wrapper,
} from './styled';

const Position = forwardRef<HTMLDivElement>((props, ref) => <ScrollPointer ref={ref} />);

interface IMenuItem {
  onChange: (index: number) => void;
  index: number;
  children: ReactChild;
  onMouseEnter: (event: MouseEvent) => void;
  onMouseLeave: (event: MouseEvent) => void;
}

const MenuItem = forwardRef<HTMLDivElement, IMenuItem>(
  ({
    onChange, index, children, onMouseEnter, onMouseLeave,
  }, ref) => (
    <SliderMenuLink
      ref={ref}
      onClick={() => onChange(index)}
      onTouchStart={() => onChange(index)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={index === 0 ? 'active' : ''}
    >
      {children}
    </SliderMenuLink>
  ),
);

interface SliderMenuIneterface {
  items: string[];
  onChange: (index: number) => void;
  positionRef: Ref<HTMLDivElement>;
  refs: MutableRefObject<Ref<HTMLDivElement>[]>;
  onItemHover?: (index: number) => void;
}

const SliderMenu = forwardRef<HTMLDivElement, SliderMenuIneterface>(
  ({
    items, onChange, positionRef, refs, onItemHover,
  }, ref) => {
    const onMouseEnter = (index: number) => {
      onItemHover(index);
    };

    const onLeave = () => {
      onItemHover(-1);
    };

    return (
      <Container ref={ref} withPaddings>
        <Wrapper>
          {items.map((item, index) => (
            <MenuItem
              key={`SliderMenu_${item}`}
              onChange={onChange}
              ref={refs.current[index]}
              index={index}
              onMouseEnter={() => onMouseEnter(index)}
              onMouseLeave={onLeave}
            >
              {item}
            </MenuItem>
          ))}
          <Position ref={positionRef} />
        </Wrapper>
      </Container>
    );
  },
);

export default SliderMenu;
