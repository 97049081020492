import React, { FC, ReactNode, useState } from 'react';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import {
  Button,
  ButtonTextWrapper,
  ClipboardIconWrapper,
  ShareIcon,
  ShareIconsWrapper,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  IconWrapper,
  CheckIcon,
} from './styled';

interface IShareButton {
  children: ReactNode;
  url: string;
  className?: string;
}

const ShareButton: FC<IShareButton> = ({
  children, url, className = '', ...props
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = (text: string): void => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setIsCopied(true);
  };

  return (
    <Button className={className} {...props}>
      <ButtonTextWrapper>{children}</ButtonTextWrapper>

      <ShareIconsWrapper>
        <ClipboardIconWrapper onClick={() => copyText(url)} title={`copy link (${url})`}>
          {isCopied ? <CheckIcon width="0.8em" /> : <ShareIcon width="0.8em" />}
        </ClipboardIconWrapper>

        <IconWrapper title="Share to facebook">
          <FacebookShareButton url={url}>
            <FacebookIcon width="0.8em" />
          </FacebookShareButton>
        </IconWrapper>

        <IconWrapper title="Share to twitter">
          <TwitterShareButton url={url}>
            <TwitterIcon width="0.8em" />
          </TwitterShareButton>
        </IconWrapper>

        <IconWrapper title="Share to linkedin">
          <LinkedinShareButton url={url}>
            <LinkedinIcon width="0.8em" />
          </LinkedinShareButton>
        </IconWrapper>
      </ShareIconsWrapper>
    </Button>
  );
};

export default ShareButton;
