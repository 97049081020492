import React, {
  FC, useState, ReactNode, useEffect, useRef,
} from 'react';

import throttle from 'utils/throttle';
import { Container, Wrapper } from './styled';

interface IFixesVisible {
  children: ReactNode;
}

const FixedVisible: FC<IFixesVisible> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const staticWrapperRef = useRef<HTMLDivElement>(null);

  const [isVisible, setIsVisible] = useState<boolean>(true);

  const resizeBlock = () => {
    if (!staticWrapperRef.current || !containerRef.current) return;
    const contentHeight = staticWrapperRef.current?.offsetHeight || 0;
    containerRef.current.style.height = `${contentHeight}px`;
  };

  const handleAppear = ({ visibility }) => {
    if (visibility) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleResize = () => {
    throttle(resizeBlock, 100)();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    resizeBlock();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container
      options={{
        threshold: 0.0,
        rootMargin: '100px 0px 0px',
      }}
      way={handleAppear}
      ref={containerRef}
    >
      <Wrapper ref={staticWrapperRef} style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        {children}
      </Wrapper>
    </Container>
  );
};

export default FixedVisible;
