import React, {
  AnchorHTMLAttributes, ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode,
} from 'react';

import { UIButton, UILink } from './styled';

interface IButton {
  className?: string;
}

interface IButtonElement extends IButton,
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode;
}

const Button: FC<IButtonElement> = ({
  children, className = '', ...props
}) => (
  <UIButton className={className} {...props}>
    {children}
  </UIButton>
);

interface IButtonLink extends IButton,
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  children: ReactNode;
}

const ButtonLink: FC<IButtonLink> = ({
  children, className = '', ...props
}) => (
  <UILink className={className} {...props}>
    {children}
  </UILink>
);

export {
  Button,
  ButtonLink,
};
