import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setActiveBlock } from 'store/actions';
import throttle from 'utils/throttle';

import { IMenuItem } from '../items';
import AnchorLinks from './AnchorLinks/AnchorLinks';

import {
  Container, Link, Count, AnchorWrapper, HTMLLink,
} from './MenuItem.styled';

interface IMenuComponent extends IMenuItem {
  location: ILocation;
  count?: number;
}

const MenuItem: FC<IMenuComponent> = ({
  title, link, submenu, count, location, target,
}) => {
  const anchorsWrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLSpanElement>(null);
  const dispatch = useDispatch();
  const activeBlock = useSelector((state: BlockState) => state?.activeBlock);

  const setCurrBlock = (block: string) => {
    const clearBlock = block.replace('#', '');
    const activeElement = document.querySelector(`[data-id="${clearBlock}"]`);

    setTimeout(() => {
      activeElement?.scrollIntoView();
    }, 0);

    dispatch(setActiveBlock(`${block}`));
  };

  const setSize = () => {
    const titleLink = titleRef.current?.parentElement;
    const wrapper = anchorsWrapperRef.current;

    if (wrapper && titleLink) {
      const height = titleLink.classList.contains('active') ? wrapper.scrollHeight : 0;
      wrapper.style.maxHeight = `${height}px`;
    }
  };

  const handleResize = () => {
    throttle(setSize, 400)();
  };

  useEffect(() => {
    setSize();
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setSize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      {target ? (
        <HTMLLink
          href={link}
          target={target}
        >
          {title}
        </HTMLLink>
      ) : (
        <Link
          to={link}
          partiallyActive={link !== '/'}
          activeClassName="active"
        >
          <span ref={titleRef}>
            {title}
          </span>
          {(count && link?.startsWith('/culture/')) ? (
            <Count>{count}</Count>
          ) : null}
        </Link>
      )}

      {submenu?.length && (
        <AnchorWrapper ref={anchorsWrapperRef}>
          <AnchorLinks
            links={submenu}
            setCurrBlock={setCurrBlock}
            activeBlock={activeBlock}
          />
        </AnchorWrapper>
      )}
    </Container>
  );
};

export default MenuItem;
