import React, { FC, ReactNode } from 'react';

import { Wrapper, TooltipWrapper, Text } from './Tooltip.styled';

interface ITooltip {
  className?: string;
  text?: string;
  children: ReactNode;
  required?: boolean;
}

const Tooltip: FC<ITooltip> = ({
  children,
  text,
  className,
  required = false,
}) => (
  <>
    {text ? (
      <TooltipWrapper className={className} required={required}>
        {children}
        <Wrapper>
          <Text>{text}</Text>
        </Wrapper>
      </TooltipWrapper>
    ) : children}
  </>
);

export default Tooltip;
