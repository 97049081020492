import styled from '@emotion/styled';

import { ButtonLink as UIButtonLink } from '../Button/Button';

export const Arrow = styled.path`
  d: path('m11 14-5 5h27l-33 33 4 4 33-33v27l5-5v-29l-2-2z');
  fill: #fff;
  transition: 0.3s;
`;

export const Icon = styled.svg`
  width: 13px;
  height: 13px;
  margin-right: 8px;
  margin-bottom: 5px;
`;

export const IconWrapper = styled.div``;

export const ButtonLink = styled(UIButtonLink)`
  background: #ff1e31;
  color: #fff;

  :hover {
    ${Arrow} {
      d: path('m25 0-5 5h27l-47 47 4 4 47-47v27l5-5v-29l-2-2z');
    }
  }
`;
