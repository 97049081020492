import * as actionTypes from './actionTypes';

export const setActiveBlock = (block: string): StateAction => ({
  type: actionTypes.SET_BLOCK,
  block,
});

export const setPreviousBlock = (block: string): StateAction => ({
  type: actionTypes.SET_PREVIOUS_BLOCK,
  block,
});

export const setCookieAgreement = (data: boolean): StateAction => ({
  type: actionTypes.SET_COOKIE_AGREEMENT,
  data,
});
