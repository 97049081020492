import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IFooter } from 'interfaces/footer';

import {
  Wrapper, Container, Legal, SocialLinks, PolicyLinks, Space,
} from './Footer.styled';

const query = graphql`
  query {
    page: strapiInfo {
      footer {
        legal
        id
        social {
          icon {
            ...MediaSVGFragment
          }
          id
          link
          text
          title
        }
        links {
          id
          link
          text
          title
        }
      }
    }
  }
`;

interface IFooterQuery {
  page: {
    footer: IFooter;
  }
}

const Footer: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IFooterQuery) => {
      const footerData = data.page.footer;
      const {
        legal, social, links,
      } = footerData;

      return (
        <Container>
          <Wrapper>
            <SocialLinks links={social} />
            <Legal>
              {legal}
            </Legal>
            <Space />
            <PolicyLinks links={links} />
          </Wrapper>
        </Container>
      );
    }}
  />
);

export default Footer;
