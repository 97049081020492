import styled from '@emotion/styled';

export const Right = styled.div`
  width: 2.2rem;
  height: 2.86rem;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--white);
  transition: opacity 0.4s;

  span {
    margin-left: 0.35rem;
    font-size: 1.2rem;
    margin-top: -0.1rem;
    transition: margin 0.4s;
  }
`;

export const Left = styled(Right)`
  justify-content: end;

  span {
    margin-right: 0.35rem;
  }
`;

export const Wrapper = styled.div`
  grid-column: 1/-1;
  grid-row: 2;
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  background: var(--black-opacity-2);
  backdrop-filter: blur(0.4rem);
  border-radius: 1.43rem;
  cursor: none;

  z-index: 2;
  position: fixed;
  pointer-events: none;

  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.1s;

  &.active {
    transform: translate(-50%, -50%) scale(1);
  }

  &.left {
    ${Left} span {
      margin-right: -0.5rem;
    }

    ${Right} {
      opacity: 0;

      span {
        margin-left: -0.5rem;
      }
    }
  }

  &.right {
    ${Right} span {
      margin-left: -0.5rem;
    }

    ${Left} {
      opacity: 0;

      span {
        margin-right: -0.5rem;
      }
    }
  }
`;
