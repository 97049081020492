import React, { forwardRef, ReactNode, useRef } from 'react';

import { Container, Wrapper } from './styled';

interface IFullHeight {
  children: ReactNode;
}

const FullHeight = forwardRef<HTMLDivElement, IFullHeight>(
  ({ children, ...props }, ref) => {
    const wrapperRef = useRef<HTMLDivElement>();

    const handleAppear = ({ visibility }) => {
      if (visibility) {
        wrapperRef.current?.classList?.add('visible');
      } else {
        wrapperRef.current?.classList?.remove('visible');
      }
    };

    const options = {
      threshold: 0.9,
      rootMargin: '0px 0px 0px',
    };

    return (
      <Container ref={wrapperRef} options={options} way={handleAppear}>
        <Wrapper ref={ref} {...props}>
          {children}
        </Wrapper>
      </Container>
    );
  },
);

export default FullHeight;
