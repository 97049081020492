import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { MIN_4K, MIN_2K, MAX_MOB_WIDTH } from 'constants/sizes';

const MiddleTextStyles = css`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0em;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (min-width: ${MIN_2K}px) {
    font-size: 34px;
    line-height: 44px;
  }

  @media (min-width: ${MIN_4K}px) {
    font-size: 68px;
    line-height: 88px;
  }
`;

const MiddleTextParagraph = styled.p`
  ${MiddleTextStyles}
`;

export default MiddleTextParagraph;
