import React, { FC } from 'react';

import { IIcon } from './interfaces';

const ShareIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M2.7156 5.542L1.3436 6.914C0.780941 7.47666 0.464844 8.23978 0.464844 9.0355C0.464844 9.83122 0.780941 10.5943 1.3436 11.157C1.90625 11.7197 2.66938 12.0358 3.4651 12.0358C4.26081 12.0358 5.02394 11.7197 5.5866 11.157L7.4146 9.328C7.75389 8.98866 8.00683 8.57287 8.15219 8.11555C8.29754 7.65822 8.33107 7.1727 8.24996 6.69974C8.16884 6.22677 7.97546 5.78016 7.68603 5.3974C7.3966 5.01464 7.01957 4.7069 6.5866 4.5L6.0006 5.086C5.9411 5.14559 5.88936 5.21245 5.8466 5.285C6.18112 5.38117 6.48476 5.56298 6.72749 5.81246C6.97022 6.06194 7.14364 6.37044 7.2306 6.70748C7.31756 7.04452 7.31505 7.39842 7.22333 7.73419C7.1316 8.06996 6.95384 8.37598 6.7076 8.622L4.8806 10.45C4.50532 10.8253 3.99633 11.0361 3.4656 11.0361C2.93487 11.0361 2.42588 10.8253 2.0506 10.45C1.67532 10.0747 1.46449 9.56573 1.46449 9.035C1.46449 8.50427 1.67532 7.99528 2.0506 7.62L2.8436 6.828C2.73171 6.40873 2.68849 5.9741 2.7156 5.541V5.542Z" />
    <path d="M4.58751 3.6716C4.24822 4.01094 3.99528 4.42672 3.84992 4.88405C3.70457 5.34138 3.67104 5.8269 3.75215 6.29986C3.83327 6.77283 4.02665 7.21943 4.31608 7.60219C4.60551 7.98495 4.98254 8.2927 5.41551 8.4996L6.19051 7.7236C5.85146 7.63265 5.54232 7.45407 5.29415 7.2058C5.04598 6.95753 4.86753 6.64831 4.77672 6.30922C4.68592 5.97013 4.68597 5.61311 4.77686 5.27404C4.86775 4.93498 5.04628 4.62581 5.29451 4.3776L7.12151 2.5496C7.49679 2.17432 8.00579 1.96349 8.53651 1.96349C9.06724 1.96349 9.57623 2.17432 9.95151 2.5496C10.3268 2.92488 10.5376 3.43387 10.5376 3.9646C10.5376 4.49533 10.3268 5.00432 9.95151 5.3796L9.15851 6.1716C9.27051 6.5916 9.31351 7.0266 9.28651 7.4586L10.6585 6.0866C11.2212 5.52394 11.5373 4.76081 11.5373 3.9651C11.5373 3.16938 11.2212 2.40625 10.6585 1.8436C10.0959 1.28094 9.33273 0.964844 8.53701 0.964844C7.7413 0.964844 6.97817 1.28094 6.41551 1.8436L4.58751 3.6716Z" />
  </svg>
);

export default ShareIcon;
