import React, {
  FC, Ref, forwardRef,
  useEffect, useRef, useState,
  ReactNode, ReactElement, MouseEvent,
} from 'react';

import Buttons from './Buttons/Buttons';

import {
  Container, Wrapper, LeftHalf, RightHalf,
} from './TouchSlider.styled';

interface ITouchSlider {
  items: string[];
  className?: string;
  itemWidth?: number;
  scrollbar?: ReactElement;
  children: ReactNode;
  onChange?: (index: number) => void;
  ref?: Ref<HTMLDivElement>;
}

const TouchSlider: FC<ITouchSlider> = forwardRef(({
  items,
  children,
  className,
  itemWidth = 100,
  onChange = () => {},
}, ref) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const pointerRef = useRef<HTMLDivElement>();

  const leftRef = useRef<HTMLDivElement>();
  const rightRef = useRef<HTMLDivElement>();

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [prevSlide, setPrevSlide] = useState<number>(0);
  const [slidePosition, setSlidePosition] = useState<string>('0%');
  const [isLeft, setIsLeft] = useState<boolean>(false);

  const setLeftCursor = () => {
    const cursor = pointerRef.current;

    if (!cursor) return;

    cursor.classList.remove('right');
    cursor.classList.add('left');
  };

  const setRightCursor = () => {
    const cursor = pointerRef.current;

    if (!cursor) return;

    cursor.classList.remove('left');
    cursor.classList.add('right');
  };

  const handleMove = (event) => {
    const container = containerRef.current;
    const cursor = pointerRef.current;

    const { target, clientX: x, clientY: y } = event as MouseEvent;

    if (target instanceof HTMLElement
      && container?.contains(target)
      && cursor) {
      cursor.classList.add('active');
      cursor.style.left = `${x}px`;
      cursor.style.top = `${y}px`;
    } else {
      cursor?.classList.remove('active');
    }
  };

  const clearCursor = () => {
    const cursor = pointerRef.current;
    cursor?.classList.remove('active');
  };

  const shiftLeft = () => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const activeSlideNode = wrapper.childNodes?.[items.length - 1] as HTMLElement;

    wrapper.classList.remove('smooth');
    setSlidePosition(`calc(-33.333% - ${activeSlideNode.offsetLeft + activeSlideNode.offsetWidth}px)`);
    setTimeout(() => {
      wrapper.classList.add('smooth');
      setSlidePosition(`calc(-33.333% - ${activeSlideNode.offsetLeft}px)`);
    }, 20);
  };

  const shiftRight = () => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const activeSlideNode = wrapper.childNodes?.[0] as HTMLElement;

    wrapper.classList.remove('smooth');
    setSlidePosition(`calc(-33.333% - ${activeSlideNode.offsetLeft - activeSlideNode.offsetWidth}px)`);
    setTimeout(() => {
      wrapper.classList.add('smooth');
      setSlidePosition(`calc(-33.333% - ${activeSlideNode.offsetLeft}px)`);
    }, 20);
  };

  const setPosition = (index: number) => {
    const wrapper = wrapperRef.current;
    const count = items.length;

    if (!wrapper) return;
    const activeSlideNode = wrapper.childNodes?.[index] as HTMLElement;

    const onLeft = count > 2
      ? (prevSlide === 0) && (index === count - 1)
      : isLeft && index === 1;
    const onRight = count > 2
      ? (prevSlide === count - 1) && (index === 0)
      : !isLeft && index === 0;

    if (onRight || (count === 1 && !isLeft)) {
      shiftRight();
    } else if (onLeft || (count === 1 && isLeft)) {
      shiftLeft();
    } else {
      setSlidePosition(`calc(-33.4% - ${activeSlideNode.offsetLeft}px)`);
    }
  };

  const setPrevious = () => {
    setPrevSlide(activeSlide);
    setIsLeft(true);
    setActiveSlide((oldSlide) => {
      const newSlide = oldSlide > 0 ? oldSlide - 1 : items.length - 1;
      return newSlide;
    });
  };

  const setNext = () => {
    setPrevSlide(activeSlide);
    setIsLeft(false);
    setActiveSlide((oldSlide) => {
      const newSlide = oldSlide < items.length - 1 ? oldSlide + 1 : 0;
      return newSlide;
    });
  };

  useEffect(() => {
    onChange(activeSlide);
    setPosition(activeSlide);
  }, [activeSlide]);

  return (
    <>
      <Buttons ref={pointerRef} />
      <Container
        className={className}
        ref={(node) => {
          containerRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
        }}
      >
        <LeftHalf
          ref={leftRef}
          onClick={setPrevious}
          onMouseOver={setLeftCursor}
          onMouseMove={handleMove}
          onMouseLeave={clearCursor}
        />
        <RightHalf
          ref={rightRef}
          onClick={setNext}
          onMouseOver={setRightCursor}
          onMouseMove={handleMove}
          onMouseLeave={clearCursor}
        />
        <Wrapper
          ref={wrapperRef}
          style={{
            width: `${items.length * 3 * itemWidth}%`,
            transform: `translateX(${slidePosition})`,
          }}
          options={{ threshold: 0.35, rootMargin: '-100px 0px' }}
          className="smooth"
        >
          {children}
          {children}
          {children}
        </Wrapper>
      </Container>
    </>
  );
});

export default TouchSlider;
