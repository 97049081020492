import styled from '@emotion/styled';

import {
  MAX_MOB_WIDTH, MIN_2K, MIN_4K, MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';

import { FullLogo } from 'UI/Icons';
import SmallLogoIcon from 'UI/Icons/SmallLogo';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
`;

export const Space = styled.div`
  height: 100%;
`;

export const MobileHeader = styled.div`
  display: none;
  background: var(--white);
  position: fixed;
  left: 0;
  top: 0;
  height: ${MOBILE_TOP_MENU_HEIGHT}px;
  width: 100vw;
  justify-items: center;
  align-items: center;
  align-content: center;
  outline: none;

  > a {
    outline: none;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: grid;
  }
`;

export const Logo = styled(FullLogo)`
  width: 112px;
  height: auto;
  margin-bottom: 2.3em;

  @media (min-width: ${MIN_2K}px) {
    width: 5em;
    height: auto;
  }

  @media (max-width: 767px) {
    width: 118px;
    height: 20px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 0;
    display: none;
  }
`;

export const SmallLogo = styled(SmallLogoIcon)`
  display: none;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: block;
    height: 20px;
    width: 20px;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100vh;
  height: 100vh;
  background: var(--black-opacity);
`;

export const Navigation = styled.nav`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  align-content: start;
  row-gap: 1em;
  background: var(--white);
  height: 100vh;
  top: 0;
  z-index: 1;
  padding: 40px 0 40px 24px;

  > a:nth-of-type(1) {
    outline: none;
  }

  @media (min-width: ${MIN_2K}px) {
    padding: 40px 0 40px 40px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding: 76px 0 40px 80px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding: 76px 0px 6em 22px;
    width: 168px;
    transform: translateX(-100%);
    transition: .4s ease-in-out;

    &.opened {
      transform: translateX(0%);
    }

    ${Logo} {
      display: none;
    }
  }
`;
