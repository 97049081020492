import styled from '@emotion/styled';

import { MAX_MOB_WIDTH } from 'constants/sizes';

const Button = styled.div`
  display: none;
  position: absolute;
  top: 18px;
  left: 25px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 10;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: block;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    background: var(--red);
    height: 1.6px;
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: .4s ease-in-out;
  }

  &:before {
    top: 5px;
  }

  &:after {
    bottom: 5px;
  }

  &.opened {
    &:before {
      top: 9px;
      transform: rotate(-45deg);
    }

    &:after {
      bottom: 9px;
      transform: rotate(45deg);
    }
  }
`;

export default Button;
