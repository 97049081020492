import React, { FC } from 'react';

import { IIcon } from './interfaces';

const CheckCircle: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="m7 0.5c-3.584 0-6.5 2.916-6.5 6.5 0 3.584 2.916 6.5 6.5 6.5 3.584 0 6.5-2.916 6.5-6.5 0-3.584-2.916-6.5-6.5-6.5zm3.383 4.322-4.2 5c-0.04607 0.05488-0.1034 0.09921-0.1681 0.13-0.0647 0.03078-0.1353 0.0473-0.2069 0.04844h-0.00843c-0.07009-3e-5 -0.1394-0.01478-0.2034-0.04332-0.06401-0.02853-0.1213-0.0702-0.1682-0.1223l-1.8-2c-0.04571-0.04849-0.08127-0.1056-0.1046-0.168-0.02332-0.06242-0.03392-0.1289-0.03119-0.1955 0.00274-0.06658 0.01875-0.132 0.04711-0.1922 0.02835-0.06031 0.06847-0.1143 0.118-0.1589 0.04953-0.04457 0.1075-0.0788 0.1704-0.1007 0.06295-0.02186 0.1296-0.03092 0.1961-0.02665 0.0665 0.00428 0.1315 0.02181 0.1911 0.05156 0.05963 0.02974 0.1127 0.07111 0.1561 0.1217l1.415 1.572 3.831-4.56c0.08593-0.09935 0.2075-0.1609 0.3384-0.1713 0.131-0.01043 0.2608 0.03108 0.3614 0.1156 0.1005 0.08449 0.1638 0.2052 0.1761 0.3359 0.0124 0.1308-0.0273 0.2612-0.1103 0.3629z" />
  </svg>
);

export default CheckCircle;
