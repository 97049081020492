export interface IAnchorLink {
  title: string;
  anchor: string | string[];
}

export interface IMenuItem {
  title: string;
  link: string;
  submenu?: IAnchorLink[];
  target?: string;
}

const mainMenu: IMenuItem = {
  title: 'Main',
  link: '/',
  submenu: [
    {
      title: 'Who we are',
      anchor: '#who-we-are',
    },
    {
      title: 'What we do',
      anchor: '#what-we-do',
    },
    {
      title: 'How we work',
      anchor: '#how-we-work',
    },
    {
      title: 'Achievements',
      anchor: '#achievements',
    },
    {
      title: 'Facilities',
      anchor: '#facilities',
    },
  ],
};

const holograktor: IMenuItem = {
  title: 'Holograktor',
  link: 'https://holograktor.com',
  target: '_blank',
};

const deepRealityRisplayMenu: IMenuItem = {
  title: 'Deep Reality\nDisplay®',
  link: '/deep-reality-display/',
  submenu: [
    {
      title: 'About',
      anchor: '#about',
    },
    {
      title: 'Use cases',
      anchor: ['#gaming', '#adas', '#truear'],
    },
    {
      title: 'Advantages',
      anchor: '#advantages',
    },
    {
      title: 'How it works',
      anchor: '#how_it_works',
    },
    {
      title: 'Partnerships',
      anchor: '#partnerships',
    },
  ],
};

const technologyMenu: IMenuItem = {
  title: 'Technology',
  link: '/technology/',
  submenu: [
    {
      title: 'Do Deep Tech',
      anchor: '#dodeeptech',
    },
    {
      title: 'Embedded',
      anchor: '#embedded',
    },
    {
      title: 'ARRE',
      anchor: '#arre',
    },
    {
      title: 'Add-on',
      anchor: '#addon',
    },
    {
      title: 'Facilities',
      anchor: '#inHouseFacilities',
    },
  ],
};

const cultureMenu: IMenuItem = {
  title: 'Culture & Jobs',
  link: '/culture/',
  submenu: [
    {
      title: 'Mission',
      anchor: '#mission',
    },
    {
      title: 'Team',
      anchor: '#team',
    },
    {
      title: 'Values',
      anchor: '#values',
    },
    {
      title: 'Culture',
      anchor: '#culture',
    },
    {
      title: 'Jobs',
      anchor: '#jobs',
    },
    {
      title: 'Locations',
      anchor: '#locations',
    },
  ],
};

export const pressAreaMenu: IMenuItem = {
  title: 'Press area',
  link: '/press-area/',
  submenu: [
    {
      title: 'Press',
      anchor: '#press',
    },
    {
      title: 'Press releases',
      anchor: '#press_releases',
    },
    {
      title: 'Media coverage',
      anchor: '#media_coverage',
    },
    /* {
      title: 'History Highlights',
      anchor: '#history',
    }, */
    {
      title: 'Events',
      anchor: '#events',
    },
    {
      title: 'Guidelines',
      anchor: '#guidelines',
    },
    {
      title: 'Social',
      anchor: '#social',
    },
  ],
};

const MenuItems: IMenuItem[] = [
  mainMenu,
  holograktor,
  deepRealityRisplayMenu,
  technologyMenu,
  cultureMenu,
];

export default MenuItems;
