import { RefObject, MutableRefObject } from 'react';

interface ISetActiveMenu {
  refs: MutableRefObject<RefObject<HTMLDivElement>[]>;
  index: number;
}

const setActiveMenu = ({ refs, index }: ISetActiveMenu): void => {
  for (let i = 0; i < refs.current?.length; i += 1) {
    const menuItem = refs.current[i].current;

    if (!menuItem) return;

    if (i === index) {
      menuItem.classList.add('active');
    } else {
      menuItem.classList.remove('active');
    }
  }
};

export default setActiveMenu;
