import React, { FC, useRef } from 'react';

import { IAnchorLink } from '../../items';

import { Container, Anchor, Progress } from './AnchorLinks.styled';

interface IAnchorLinks {
  links: IAnchorLink[];
  activeBlock: string;
  setCurrBlock: (block: string) => void;
}

const AnchorLink: FC<IAnchorLinks> = ({ links, activeBlock, setCurrBlock }) => {
  const progrtessRef = useRef<HTMLDivElement>();
  const count = links.length;

  const getProgress = () => {
    if (count <= 0) return 0;

    const activeIndex = links.findIndex(({ anchor }) => anchor.includes(activeBlock));
    const firstItemSize = 100 / count;

    return (activeIndex / count) * 100 + firstItemSize - 5;
  };

  const getIsActive = (anchor: string | string[]): boolean => {
    if (Array.isArray(anchor)) {
      return anchor.some((item) => item === activeBlock);
    }
    return anchor === activeBlock;
  };

  return (
    <Container>
      <Progress
        ref={progrtessRef}
        style={{ transform: `translateY(${getProgress()}%)` }}
      />

      {links.map(({ title, anchor }) => (
        <Anchor
          key={`submenu-item_${Array.isArray(anchor) ? anchor[0] : anchor}`}
          onClick={() => setCurrBlock(Array.isArray(anchor) ? anchor[0] : anchor)}
          className={getIsActive(anchor) ? 'active' : ''}
        >
          {title}
        </Anchor>
      ))}
    </Container>
  );
};

export default AnchorLink;
