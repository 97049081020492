import React, { FC, useState, useEffect } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import { IFooter } from 'interfaces/footer';
import { IStrapiVacanvies } from 'interfaces/vacancy';

import MenuItem from './MenuItem/MenuItem';
import items, { pressAreaMenu } from './items';
import BurgerButton from './BurgerButton/BurgerButton';

import {
  Backdrop, Logo, MobileHeader, Navigation, Wrapper, Space, SmallLogo,
} from './Menu.styled';

const query = graphql`
  query {
    allStrapiVacancies {
      totalCount
    }

    page: strapiInfo {
      footer {
        pages {
          id
          link
          text
          title
        }
      }
    }
  }
`;

interface IMenuQuery extends IStrapiVacanvies {
  page: {
    footer: IFooter;
  }
}

interface IMenu {
  location: ILocation;
}

const Menu: FC<IMenu> = ({ location }) => {
  const [opened, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (opened) {
      document.querySelector('body').classList.add('stop-scrolling');
    } else {
      document.querySelector('body').classList.remove('stop-scrolling');
    }
  }, [opened]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { hash, pathname } = location;
      const submenu = [...items, pressAreaMenu]
        .find(({ link }) => link?.replace(/\//g, '') === pathname?.replace(/\//g, ''))?.submenu;

      if (!submenu) return;
      const activeAnchor = submenu.find(({ anchor }) => anchor === hash);
      if (activeAnchor) {
        const activeElement = document.querySelector(`[data-id=${hash.replace(/#/g, '')}]`);
        activeElement?.scrollIntoView();
      }
    }
  }, []);

  return (
    <StaticQuery
      query={query}
      render={(data: IMenuQuery) => (
        <Wrapper>
          <BurgerButton
            opened={opened}
            onClick={opened ? handleClose : handleOpen}
          />
          <MobileHeader>
            <Link to="/" aria-label="WayRay">
              <Logo />
              <SmallLogo />
            </Link>
          </MobileHeader>

          <Navigation className={opened ? 'opened' : ''}>
            <Link to="/" aria-label="WayRay"><Logo /></Link>
            {items.map((menuItem) => menuItem && (
              <MenuItem
                key={`menu-item_${menuItem.link}`}
                title={menuItem.title}
                link={menuItem.link}
                target={menuItem.target}
                count={data.allStrapiVacancies.totalCount}
                submenu={menuItem.submenu}
                location={location}
              />
            ))}
            <Space />
            {data?.page?.footer?.pages?.map(({ title, link, id }) => (
              <MenuItem
                key={`menu-item_${id}`}
                title={title}
                link={/^\/press-area/.test(link) ? pressAreaMenu.link : link}
                location={location}
                submenu={/^\/press-area/.test(link) ? pressAreaMenu.submenu : null}
              />
            ))}
          </Navigation>
          {opened && (
            <Backdrop onClick={handleClose} />
          )}
        </Wrapper>
      )}
    />
  );
};

export default Menu;
