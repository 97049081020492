import { css } from '@emotion/react';
import styled from '@emotion/styled';

import './variables.css';

import {
  MAX_MOB_WIDTH, MIN_2K, MIN_4K, MAX_TABLET_WIDTH, STANDART_DESKTOP_WIDTH,
} from 'constants/sizes';

export const PageWrapper = styled.div`
  grid-column: 2;
  background-color: var(--athens-gray);
  display: grid;
  grid-auto-flow: row;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
  min-height: 100vh;

  @media (min-width: ${MIN_2K}px) {
    grid-template-columns: 6.55% 1fr;
  }

  @media (min-width: ${STANDART_DESKTOP_WIDTH}px) {
    grid-template-columns: 14% 1fr;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-template-columns: 10.9% 1fr;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: 170px 1fr;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: 0px 1fr;
  }
`;

export const GlobalStyles = css`
  html {
    font-size: 14px;
    line-height: 18px;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 12px;
      line-height: 16px;
    }

    @media (min-width: ${MIN_2K}px) {
      font-size: 24px;
      line-height: 30px;
    }

    @media (min-width: ${MIN_4K}px) {
      font-size: 48px;
      line-height: 58px;
    }
  }

  body {
    font-family: 'Way Ray Grotesk', sans-serif;
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 72px;
    line-height: 72px;
    letter-spacing: -0.02em;

    margin: 0;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      font-size: 60px;
      line-height: 60px;
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 36px;
      line-height: 34px;
      letter-spacing: -0.01em;
    }

    @media (min-width: ${MIN_2K}px) {
      font-size: 96px;
      line-height: 96px;
      letter-spacing: -0.02em;
    }

    @media (min-width: ${MIN_4K}px) {
      font-size: 180px;
      line-height: 180px;
    }
  }

  h2 {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.02em;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.01em;
    }

    @media (min-width: ${MIN_4K}px) {
      font-size: 120px;
      line-height: 120px;
    }
  }

  h3 {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.01em;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 28px;
      line-height: 32px;
    }

    @media (min-width: ${MIN_2K}px) {
      font-size: 60px;
      line-height: 60px;
      letter-spacing: -0.02em;
    }

    @media (min-width: ${MIN_4K}px) {
      font-size: 120px;
      line-height: 120px;
    }
  }

  p {
    letter-spacing: 0.01em;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      letter-spacing: 0.02em;
    }

    @media (min-width: ${MIN_2K}px) {
      letter-spacing: 0em;
    }

    @media (min-width: ${MIN_4K}px) {
      letter-spacing: -0.02em;
    }
  }

  u {
    text-decoration: none;
    white-space: nowrap;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }

  small {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;

    @media (min-width: ${MIN_2K}px) {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }

    @media (min-width: ${MIN_4K}px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  body.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  svg {
    display: block;
  }

  button {
    border: none;
    background: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
