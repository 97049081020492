import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MIN_2K, MIN_4K } from 'constants/sizes';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(auto-fit, 2em);
  align-content: start;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 2em;
  padding-left: 1.4em;
  position: relative;
  overflow: hidden;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-rows: repeat(auto-fit, 32px);
    padding-left: 0;
  }
`;

export const Anchor = styled.span`
  cursor: pointer;
  color: var(--black-opacity);
  padding: 0.4em 0;
  white-space: pre;

  &:hover {
    color: var(--black);
  }

  &.active {
    color: var(--black);
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-left: 0;
  }

  @media (min-width: ${MIN_2K}px) {
    font-size: 20px;
  }

  @media (min-width: ${MIN_4K}px) {
    font-size: 34px;
  }
`;

export const Progress = styled.div`
  position: absolute;
  top: calc(4px - 100%);
  left: 0;
  width: 2px;
  height: 100%;
  background: var(--red);
  transition: transform 0.3s linear;
  transform: translateY(0%);

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }

  @media (min-width: ${MIN_2K}px) {
    width: 3px;
  }

  @media (min-width: ${MIN_4K}px) {
    width: 5px;
  }
`;
