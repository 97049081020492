import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import { IMetaPreview, IQueryPage } from 'interfaces/page';
import { IMediaImage } from 'interfaces/media';
import { MAX_MOB_WIDTH } from 'constants/sizes';

const query = graphql`
  query {
    page: strapiInfo {
      meta {
        ...StrapiMetaFragment
      }
    }
  }
`;

interface IHead {
  title?: string;
  description?: string;
  preview?: IMetaPreview | IMediaImage;
  slug?: string;
}

const websiteUrl = 'https://wayray.com';

const Head: FC<IHead> = ({
  title,
  description,
  preview,
  slug = '',
}) => {
  const changeHeight = () => {
    const viewport = document.querySelector('meta[name="viewport"]');
    const windowWidth = window?.innerWidth || 0;
    const windowHeight = window?.innerHeight || 0;

    if (windowWidth <= MAX_MOB_WIDTH && windowHeight > windowWidth * 1.5) {
      viewport.setAttribute(
        'content',
        `width=device-width, ${windowWidth <= MAX_MOB_WIDTH ? `height=${windowHeight},` : ''} initial-scale=1.0`,
      );
    } else if (windowWidth > MAX_MOB_WIDTH) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
    }
  };

  useEffect(() => {
    const windowWidth = window?.innerWidth || 0;

    if (windowWidth <= MAX_MOB_WIDTH) {
      changeHeight();
      window.addEventListener('resize', changeHeight);
    }

    return () => {
      window.removeEventListener('resize', changeHeight);
    };
  }, []);

  return (
    <StaticQuery
      query={query}
      render={(data: IQueryPage) => {
        const siteInfo = data.page.meta;

        const large = typeof preview === 'string'
          ? preview
          : (preview?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src
            || siteInfo?.preview?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src);
        const pageTitle = `${siteInfo.title}${title?.length ? ` | ${title}` : ''}`;

        const pageDescription = description && description.length
          ? description
          : siteInfo.description;

        return (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={pageTitle}
          >
            <title>{pageTitle}</title>
            {/* <meta
              name="viewport"
            /> */}

            <meta
              name="description"
              content={pageDescription}
            />

            <meta name="twitter:image" content={`${websiteUrl}${large}`} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`${websiteUrl}/${slug}`} />
            <meta property="og:site_name" content={pageTitle} />
            <meta
              property="og:image"
              itemProp="image primaryImageOfPage"
              content={`${websiteUrl}${large}`}
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:domain" content={websiteUrl} />
            <meta name="twitter:title" property="og:title" itemProp="name" content={pageTitle} />
            <meta name="twitter:description" property="og:description" itemProp="description" content={pageDescription} />
            <meta name="facebook-domain-verification" content="uv26ehvrfvhkhmzcah9s6onzyknitn" />
          </Helmet>
        );
      }}
    />
  );
};

export default Head;
