import React, { forwardRef } from 'react';

import { Wrapper, Left, Right } from './Buttons.styled';

interface IButtons {
  className?: string;
}

const Buttons = forwardRef<HTMLDivElement, IButtons>(({ className }, ref) => (
  <Wrapper className={className} ref={ref}>
    <Left>
      <span>←</span>
    </Left>
    <Right>
      <span>→</span>
    </Right>
  </Wrapper>
));

export default Buttons;
