import styled from '@emotion/styled';

import Intersection from '../Intersection/Intersection';

export const Wrapper = styled.div`
  height: 100vh;

  > * {
    height: 100%;
  }
`;

export const Container = styled(Intersection)`
  height: 100vh;

  &.visible {
    > ${Wrapper} {
      height: calc(var(--vh, 1vh) * 100);
    }
  }
`;
