import styled from '@emotion/styled';

import Intersection from '../Intersection/Intersection';

export const Wrapper = styled(Intersection)`
  height: 100vh;
  width: 100%;
  transition: 0.1s;
`;

export const Container = styled(Intersection)`
  height: 100vh;
  width: 100%;

  &.visible {
    ${Wrapper} {
      height: calc(var(--vh, 1vh) * 100);
    }
  }
`;
