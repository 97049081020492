import { RefObject } from 'react';

import { MAX_TABLET_WIDTH } from 'constants/sizes';

interface IScrollToSlide {
  containerRef: RefObject<HTMLDivElement>;
  slide: number;
  smooth?: boolean;
}

export const scrollToSlide = ({ containerRef, slide, smooth = false }: IScrollToSlide): void => {
  const containerElement = containerRef.current;

  if (containerElement) {
    const top = Math.round(containerElement.getBoundingClientRect().top + window.pageYOffset);

    const positionY = window.innerHeight * slide;

    window.scrollTo({
      top: top + positionY + 20,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }
};

interface IScrollPointer {
  ref: RefObject<HTMLDivElement>;
  menuRefs: RefObject<HTMLDivElement>[];
  position: number;
  slide: number;
}

export const scrollPointer = ({
  ref, menuRefs, slide, position,
}: IScrollPointer): void => {
  const pointer = ref.current;

  if (!pointer) return;

  if (window.innerWidth <= MAX_TABLET_WIDTH) {
    const startTop = menuRefs?.[slide]?.current?.offsetTop || 0;
    const height = menuRefs?.[slide]?.current?.offsetHeight;

    pointer.style.transform = `translateY(${Math.round(startTop + height * position)}px)`;
  } else {
    const startLeft = menuRefs?.[slide]?.current?.offsetLeft || 0;
    const nextLeft = menuRefs?.[slide + 1]?.current?.offsetLeft
      || menuRefs?.[slide]?.current?.offsetWidth + startLeft;

    const fullWidth = nextLeft - startLeft;

    pointer.style.transform = `translateX(${Math.round(startLeft + fullWidth * position)}px)`;
  }
};
