import React, {
  FC, useEffect, useRef, ReactNode,
} from 'react';

import TableWrapper from './styled';

interface ITable {
  children: ReactNode;
  className: string;
}

const Table: FC<ITable> = ({ children, className }) => {
  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    const titles = tableRef.current.querySelectorAll('th');
    const rows = tableRef.current.querySelectorAll('tr');

    const titleNames = Array.from(titles).map((th) => th.innerText);

    rows.forEach((row) => {
      const cells = row.querySelectorAll('td');
      cells.forEach((cell, index) => {
        cell.style.setProperty('title', `"${titleNames[index]}"`);
      });
    });
  }, []);

  return (
    <TableWrapper className={className} ref={tableRef}>
      {children}
    </TableWrapper>
  );
};

export default Table;
