import React, { FC } from 'react';

import Button from './BurgerButton.styled';

interface IBurgerButton {
  opened: boolean;
  onClick: () => void;
}

const BurgerButton: FC<IBurgerButton> = ({ opened, onClick }) => (
  <Button className={opened ? 'opened' : 'closed'} onClick={onClick} />
);

export default BurgerButton;
