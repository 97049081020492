import React, { FC } from 'react';

import { IMediaSvg } from 'interfaces/media';

interface IGatsbySvg {
  className?: string;
  media: IMediaSvg;
  wrapper?: keyof JSX.IntrinsicElements;
  title?: string;
}

const GatsbySvg: FC<IGatsbySvg> = ({
  className,
  media,
  wrapper = 'span',
  title,
  ...res
}) => {
  const Tag = wrapper;
  if (!media) return null;

  return (
    <Tag
      dangerouslySetInnerHTML={{ __html: media.localFile?.childrenSvg?.[0]?.content.data }}
      className={className}
      title={title}
      {...res}
    />
  );
};

export default GatsbySvg;
