import styled from '@emotion/styled';
import { MAX_TABLET_WIDTH } from 'constants/sizes';

export const Wrapper = styled.div`
  position: relative;
  font-weight: 500;
`;

export const ActiveLabel = styled.span`
  cursor: pointer;
  color: var(--black);

  ::after {
    content: '';
    display: inline-block;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid;
    margin-left: 5px;
    margin-bottom: 2px;
    transition: 0.4s ease-in-out;
  }

  &.active {
    ::after {
      transform: rotate(180deg);
      color: var(--red);
    }
  }
`;

export const ValuesWrapper = styled.ul`
  opacity: 0;
  position: absolute;
  transform: translateY(-1rem);
  transition: 0.4s ease-in-out;
  pointer-events: none;
  top: calc(100% + 1rem);
  color: var(--gray);

  &.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    background: var(--white);
    box-shadow: 0 0 0 1px var(--border-color);
  }
`;

export const Label = styled.li``;

export const LabelItem = styled.span`
  cursor: pointer;
  display: block;
  padding: 0.1rem 0;

  :hover {
    color: var(--black);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    width: 100%;
    min-width: 150px;
    padding: 0.4rem 0.8rem;
  }
`;
