import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';

export default styled.table`
  font-size: 0.85em;
  margin: 2em -5% 4em;
  grid-column: 1/-1;
  background: #fff;
  box-shadow: 0px 3em 3em -1.5em rgba(0, 0, 0, 0.08);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin: 2em 0 4em;
  }

  th {
    padding: 1em 1.5em;
    text-align: left;
    font-weight: 500;
    color: var(--gray);
  }

  td {
    padding: 1.5em;
    text-align: left;
  }

  tbody {
    tr:nth-of-type(2n + 1) {
      background: var(--athens-gray);
    }
  }

  thead {
    @media (max-width: ${MAX_MOB_WIDTH}px) {
      display: none;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    max-width: 100%;
    background: none;
    box-shadow: none;

    tbody {
      display: grid;
      grid-auto-flow: row;
      gap: 20px;
      background: none;

      tr {
        background: var(--white);
        display: grid;
        grid-auto-flow: row;
        box-shadow: 0px 3em 3em -1.5em rgba(0, 0, 0, 0.08);
      }
    }

    td {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      background: var(--white);
      gap: 20px;
      text-align: right;

      &:nth-of-type(2n) {
        background: var(--athens-gray);
      }

      ::before {
        content: var(--title);
        font-weight: 500;
        color: var(--gray);
      }
    }
  }
`;
