import React, { FC, ReactNode } from 'react';
import { Global } from '@emotion/react';
import { Provider } from 'react-redux';

import { store } from 'store';

import Menu from 'components/Menu/Menu';
import CookieModal from 'components/blocks/CookieModal/CookieModal';
import ModalButton from 'components/blocks/ModalBlock/ModalButton/ModalButton';

import { GlobalStyles, Container, PageWrapper } from './Layout.styled';
import Analytics from './Head/Analytics';
import Head from './Head/Head';
import Footer from '../Footer/Footer';

import './fragments/widgets';
import './fragments/pages';
import './fragments/components';

import './fonts.css';
import './variables.css';
import './common.css';

const simplePages = ['/links/', '/links'];

interface ILayout {
  children: ReactNode;
  location: ILocation;
  path: string;
}

const Layout: FC<ILayout> = ({
  children, location, path,
}) => (
  <Provider store={store}>
    {!simplePages.includes(path) ? (
      <Container>
        <Head />
        <Global styles={GlobalStyles} />
        <Analytics />

        <Menu location={location} />

        <PageWrapper>
          {/* <ModalButton /> */}

          {children}

          <CookieModal />
          <Footer />
        </PageWrapper>
      </Container>
    ) : (
      <>
        <Head />
        <Global styles={GlobalStyles} />
        <Analytics />

        {children}
      </>
    )}
  </Provider>
);

export default Layout;
