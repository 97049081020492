import React, { FC, HTMLAttributes } from 'react';

import { IconWrapper, DownloadIcon } from './DownloadButton.styled';

interface IDownloadButton extends HTMLAttributes<HTMLSpanElement> {
  className?: string;
}

const DownloadButton: FC<IDownloadButton> = ({
  className = '',
  ...res
}) => (
  <IconWrapper className={className} {...res}>
    <DownloadIcon />
  </IconWrapper>
);

export default DownloadButton;
