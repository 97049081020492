import React, {
  ReactNode, HTMLAttributes, useEffect, useRef, forwardRef,
} from 'react';

export interface IHandler {
  title: string;
  visibility: boolean;
  intersectionRatio: number;
  direction: 'down' | 'up';
}

export interface IOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number[] | number;
}

interface IIntersection extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  htmlId?: string;
  way?: (data: IHandler) => void;
  options?: IOptions;
  children: ReactNode;
  className?: string;
}

const Intersection = forwardRef<HTMLDivElement, IIntersection>(
  ({
    children, way = () => {}, id, options = {}, className, htmlId, ...props
  }, ref) => {
    const containerRef = useRef<HTMLDivElement>();
    let previousY = 0;

    const handler = (entries: IntersectionObserverEntry[]) => {
      const { intersectionRatio, isIntersecting, boundingClientRect } = entries[0];
      const currentY = boundingClientRect.y;

      way({
        title: id,
        visibility: isIntersecting,
        intersectionRatio,
        direction: currentY < previousY ? 'down' : 'up',
      });

      previousY = currentY;
    };

    useEffect(() => {
      if (typeof IntersectionObserver !== 'undefined' && containerRef.current) {
        const observerOptions = {
          threshold: 0,
          rootMargin: '-100px 0px -100px 0px',
          ...options,
        };
        const observer = new IntersectionObserver(handler, observerOptions);
        observer.observe(containerRef.current);

        return () => {
          observer.disconnect();
        };
      }
      return null;
    }, []);

    return (
      <div
        id={htmlId}
        ref={(node) => {
          containerRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
        }}
        data-id={id}
        className={className}
        {...props}
      >
        {children}
      </div>
    );
  },
);

export default Intersection;
