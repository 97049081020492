/* eslint-disable */

import React from 'react';
import Layout from 'components/Layout/Layout';

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) =>  (
  <Layout location={props.location} path={props.location.pathname}>
    {element}
  </Layout>
);
