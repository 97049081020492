import React, { FC } from 'react';

import { ISocialLink } from '../type';
import {
  Container, Item, IconWrapper, Name,
} from './SocialLinks.styled';

interface ISocialLinks {
  className?: string;
  links: ISocialLink[];
}

const SocialLinks: FC<ISocialLinks> = ({ className, links, ...props }) => (
  <Container className={className} {...props}>
    {links.map(({
      id, icon, title, link,
    }) => (
      <Item key={`footer-link_${id}`} href={link} title={title}>
        <IconWrapper
          wrapper="div"
          media={icon}
        />
        <Name>{title}</Name>
      </Item>
    ))}
  </Container>
);

export default SocialLinks;
