import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';
import { GatsbySvg } from 'UI/';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 1.8em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const Name = styled.small``;

export const IconWrapper = styled(GatsbySvg)`
  background: var(--black);
  min-width: 1.7em;
  min-height: 1.7em;
  width: 1.7em;
  height: 1.7em;
  padding: 0.4em;
  margin-right: 0.5em;
  transition: 0.4s;

  svg {
    fill: var(--white);
  }
`;

export const Item = styled.a`
  display: flex;
  align-items: center;

  :hover {
    ${IconWrapper} {
      background: var(--red);
    }
  }
`;
