import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

export const ArrowLinkStyles = css`
  ::after {
    content: '↗';
    color: var(--red);
    display: inline;
    font-size: 0.7em;
    line-height: 1;
    font-weight: 500;
    margin-left: 0.3em;
  }
`;

export const ArrowLink = styled.a`
  :hover {
    ${ArrowLinkStyles}
  }
`;

export const ArrowGatsbyLink = styled(Link)`
  :hover {
    ${ArrowLinkStyles}
  }
`;
