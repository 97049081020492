import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';

import { COOKIE_AGREEMENT } from 'constants/cookie';
import { setCookieAgreement } from 'store/actions';

import {
  Container, Paragraph, SettingsButton, AgreeButton,
} from './CookieModal.styled';

const CookieModal: FC = () => {
  const dispatch = useDispatch();
  const cookieAgreement = useSelector((state: BlockState) => state?.cookieAgreement);

  const [cookieModalActive, setCookieModalActive] = useState(false);

  const handleSaveAgreement = () => {
    setCookieModalActive(false);
    localStorage.setItem(COOKIE_AGREEMENT, JSON.stringify(true));
  };

  const handleCloseModal = () => setCookieModalActive(false);

  useEffect(() => {
    setCookieModalActive(!cookieAgreement);
  }, [cookieAgreement]);

  useEffect(() => {
    const isAgreed: string = localStorage.getItem(COOKIE_AGREEMENT);
    setCookieModalActive(!JSON.parse(isAgreed));
    dispatch(setCookieAgreement(Boolean(JSON.parse(isAgreed))));
  }, []);

  return (cookieAgreement || !cookieModalActive) ? null : (
    <Container>
      <Paragraph>
        To continue browsing
        {' '}
        <Link to="https://wayray.com/" onClick={handleCloseModal}>
          wayray.com
        </Link>
        , please agree with the use of automatic services aimed at collecting and processing your
        personal data (cookies). We use cookies to tailor content, track visits and provide a safer
        experience. However, you can change your
        {' '}
        <Link to="/cookie-settings/" onClick={handleCloseModal}>
          Cookie settings
        </Link>
        {' '}
        and limit those uses at any time. Learn more:
        {' '}
        <Link to="/policy/" onClick={handleCloseModal}>
          Privacy Policy
        </Link>
        {' '}
        <br />
        <br />
        If you want to enable all cookies, please click “Yes, I agree”.
      </Paragraph>

      <SettingsButton
        href="/cookie-settings/"
        onClick={handleCloseModal}
      >
        Cookie settings
      </SettingsButton>
      <AgreeButton onClick={handleSaveAgreement}>Yes, I agree</AgreeButton>
    </Container>
  );
};

export default CookieModal;
