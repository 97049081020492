import styled from '@emotion/styled';
import { Link as UILink } from 'gatsby';

import { MIN_2K, MIN_4K, MAX_MOB_WIDTH } from 'constants/sizes';
import { css } from '@emotion/react';

export const Container = styled.div``;

export const AnchorWrapper = styled.div`
  position: relative;
  overflow: hidden;
  opacity: .0;
  max-height: 0px;
  transition: max-height .6s ease-in-out, opacity .6s ease-in-out;
`;

const LinkStyles = css`
  font-weight: 500;
  line-height: 20px;
  transition: color 0.2s linear;
  white-space: pre;

  &.active {
    color: var(--red);

    + ${AnchorWrapper} {
      opacity: 1;
    }
  }

  :hover {
    color: var(--red);
  }

  @media (min-width: ${MIN_2K}px) {
    font-size: 20px;
  }

  @media (min-width: ${MIN_4K}px) {
    font-size: 40px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    font-size: 14px;
  }
`;

export const HTMLLink = styled.a`
  ${LinkStyles}
`;

export const Link = styled(UILink)`
  ${LinkStyles}
`;

export const Count = styled.sup`
  font-weight: 500;
  font-size: 0.6em;
  line-height: 1em;
  color: var(--red);
  display: inline-block;
  margin-left: 0.2em;
  transform: translateY(-0.7em);
`;
