import styled from '@emotion/styled';

import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';

import Layout from '../../Layout/Layout';

export const Container = styled(Layout)`
  position: absolute;

  top: 0;
  left: 0;
  width: auto;
  color: #fff;
  z-index: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    left: auto;
    right: 0;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) and (orientation: portrait) {
    margin-top: 0;
  }
`;

export const SliderMenuLink = styled.span`
  font-weight: 500;
  opacity: 1;
  transition: 0.2s linear;
  transition-property: color, opacity;
  cursor: pointer;
  outline: none;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  color: var(--white);
  will-change: color;

  &.active {
    color: var(--red);
  }

  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.6;
    }
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    color: var(--white-opacity);
    justify-self: end;

    &.active {
      color: var(--red);
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    height: 30px;
  }

  @media (min-width: ${MIN_2K}px) {
    font-size: 0.8em;
  }
`;

export const ScrollPointer = styled.div`
  width: 40px;
  height: 2px;
  background: var(--red);
  position: absolute;
  bottom: 0;
  left: 0px;
  will-change: transform;
  transition: transform 0.1s;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    top: 10px;
    right: -10px;
    height: 20px;
    width: 2px;
    left: auto;
  }

  @media (min-width: ${MIN_2K}px) {
    bottom: -10px;
  }

  @media (min-width: ${MIN_4K}px) {
    bottom: -30px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  justify-content: start;
  gap: 40px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: auto;
    gap: 0px;
    justify-content: end;
    grid-auto-flow: row;
    align-items: end;
    left: initial;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: auto;
  }
`;
