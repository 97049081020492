import styled from '@emotion/styled';

const NextText = styled.span`
  cursor: pointer;
  font-weight: 500;

  ::after {
    content: '';
    font-weight: 600;
    display: inline-block;
    width: 0.5em;
    height: 0.8em;
    transform: translateY(0.15em) translateX(0.6em);
    background-image: url('/svg/learn-more-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 0;
    will-change: margin-left;
    transition: 0.2s ease-in-out;
  }

  :hover {
    ::after {
      display: inline-block;
      margin-left: 0.5em;
    }
  }
`;

export default NextText;
