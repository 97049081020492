import styled from '@emotion/styled';

import {
  MIN_4K,
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';

import Intersection from '../Intersection/Intersection';

export const Container = styled.div`
  position: relative;
`;

export const Wrapper = styled(Intersection)`
  width: auto;
  top: 0;
  left: 0;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 20px;
  position: relative;

  &.smooth {
    transition: transform 0.4s ease-in-out;
  }

  &.wheel {
    ::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    * {
      pointer-events: none !important;
    }
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    gap: 16px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    gap: 20px;
  }

  @media (min-width: ${MIN_4K}px) {
    gap: 40px;
  }
`;

export const LeftHalf = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
  cursor: none;
`;

export const RightHalf = styled(LeftHalf)`
  left: 50%;
`;
