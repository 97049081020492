import React, {
  FC, useEffect, useRef, useState,
} from 'react';

import {
  ActiveLabel, Label, ValuesWrapper, Wrapper, LabelItem,
} from './Dropdown.styled';

interface IDropDownValue {
  label: string;
  value: IValue;
}

type IValue = string | number | boolean;

interface IDropdown {
  activeValue: IValue;
  values: IDropDownValue[];
  onSelect: (value: IValue) => void;
  className?: string;
}

const Dropdown: FC<IDropdown> = ({
  activeValue,
  values,
  onSelect,
  className,
}) => {
  if (!values?.length) return null;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [opened, setOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setOpen((oldValue) => !oldValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: IValue) => {
    onSelect(value);
    setOpen(false);
  };

  const handleWindowClick = () => {
    handleClose();
    window.removeEventListener('click', handleWindowClick);
  };

  useEffect(() => {
    if (opened) window.addEventListener('click', handleWindowClick);
    else window.removeEventListener('click', handleWindowClick);
  }, [opened]);

  return (
    <Wrapper className={className} ref={wrapperRef}>
      <ActiveLabel
        role="button"
        onClick={handleToggle}
        onKeyDown={handleToggle}
        tabIndex={0}
        className={opened ? 'active' : ''}
      >
        {values?.find(({ value }) => value === activeValue)?.label}
      </ActiveLabel>
      <ValuesWrapper className={opened ? 'active' : ''}>
        {values.map((item: IDropDownValue) => (
          <Label
            key={`dropdown_${String(item.value)}`}
            className={activeValue === item.value ? 'active' : ''}
          >
            <LabelItem
              role="button"
              onClick={() => handleSelect(item.value)}
              onKeyDown={() => handleSelect(item.value)}
              tabIndex={0}
            >
              {item.label}
            </LabelItem>
          </Label>
        ))}
      </ValuesWrapper>
    </Wrapper>
  );
};

export default Dropdown;
