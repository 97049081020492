import styled from '@emotion/styled';

const SectionTitle = styled.small`
  color: var(--red);
  display: inline-block;
  margin-bottom: 1.6em;
  font-weight: 600;
`;

export default SectionTitle;
