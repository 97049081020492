import React, { FC } from 'react';

import {
  Link, IconWrapper, Icon,
} from './FileLink.styled';

interface IFileLink {
  className?: string;
  text: string;
  link: string;
  title?: string;
}

const FileLink: FC<IFileLink> = ({
  text, link, title, className,
}) => (
  <Link className={className} href={link} download title={title}>
    <IconWrapper>
      <Icon />
    </IconWrapper>
    {text}
  </Link>
);

export default FileLink;
