import React, { FC, Fragment, ReactChild } from 'react';
import Markdown from 'markdown-to-jsx';

import { IMediaFile } from 'interfaces/media';

import Table from './Table/Table';
import {
  Image, Container, Title, HeaderOne, HeaderTwo, Description,
} from './styled';

interface IArticle {
  text: string;
  title?: string;
  description?: string;
  className?: string;
  files?: IMediaFile[];
}

interface IComponent {
  children: ReactChild[];
}

interface IImage extends IComponent {
  src: string;
  alt: string;
  title: string;
  className?: string;
}

const Article: FC<IArticle> = ({
  text, title, description = '', className, files, ...props
}) => {
  const Picture: FC<IImage> = (res) => (
    <Image files={files} {...res} />
  );

  return (
    <Container className={className} {...props}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      <Markdown
        options={{
          wrapper: Fragment,
          overrides: {
            h1: HeaderOne,
            h2: HeaderTwo,
            h3: HeaderTwo,
            img: Picture,
            table: Table,
            p: {
              component: ({ children }: IComponent) => (children.some(
                (child: JSX.Element) => child.type && child.type === Picture,
              ) ? (
                <>{children}</>
                ) : (
                  <p {...props}>{children}</p>
                )),
            },
          },
        }}
      >
        {text}
      </Markdown>
    </Container>
  );
};

export default Article;
