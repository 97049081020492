import React, { FC } from 'react';

import { ButtonLink, Icon, Arrow } from './styled';

interface IGreenhouseButton {
  link: string;
}

const GreenhouseButton: FC<IGreenhouseButton> = ({ link, children, ...props }) => (
  <ButtonLink href={link} target="_blank" {...props}>
    <Icon version="1.1" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
      <Arrow />
    </Icon>
    {children}
  </ButtonLink>
);

export default GreenhouseButton;
