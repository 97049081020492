import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const Analytics: FC = () => (
  <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-115745579-1" />

    <script type="text/javascript">
      {`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(53337466, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });
      `}
    </script>
    {/* Facebook Pixel Code */}
    <script type="text/javascript">
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1904251343168406');
        fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=1904251343168406&ev=PageView&noscript=1"
        alt=""
      />
      `}
    </noscript>
    {/* End Facebook Pixel Code */}
  </Helmet>
);

export default Analytics;
