import { SET_BLOCK, SET_PREVIOUS_BLOCK, SET_COOKIE_AGREEMENT } from './actionTypes';

import initialState from './initialState';

const reducer = (state: BlockState = initialState, action: StateAction): BlockState => {
  switch (action.type) {
    case SET_BLOCK: {
      const { block = '' } = action;

      if (typeof window === 'undefined') return state;

      const { history } = window;
      history.replaceState(history.state, null, block);

      return {
        ...state,
        previousBlock:
          state.activeBlock && state.activeBlock.length > 0 ? state.activeBlock : block,
        activeBlock: block,
      };
    }

    case SET_PREVIOUS_BLOCK:
      if (typeof window !== 'undefined' && action.block === state.activeBlock) {
        window.location.hash = String(state.previousBlock);

        return {
          ...state,
          previousBlock: action.block,
          activeBlock: state.previousBlock,
        };
      }
      return state;

    case SET_COOKIE_AGREEMENT:
      return {
        ...state,
        cookieAgreement: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
