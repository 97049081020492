import styled from '@emotion/styled';
import { HTMLProps } from 'react';

import { DownloadIcon as UIDownloadIcon } from '../Icons';

export const IconWrapper = styled.span<HTMLProps<HTMLSpanElement>>`
  width: 1.5em;
  height: 1.5em;
  min-width: 30px;
  min-height: 30px;
  background: var(--border-color);
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
  transition: .2s ease-in-out;
`;

export const DownloadIcon = styled(UIDownloadIcon)`
  width: 0.5em;
  height: auto;
  transition: none;
`;
