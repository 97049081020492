import React, { FC } from 'react';

import { IIcon } from './interfaces';

const LinkedinIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="m2.159 3.383c0.6713 0 1.216-0.5442 1.216-1.216 0-0.6713-0.5442-1.216-1.216-1.216-0.6713 0-1.216 0.5442-1.216 1.216 0 0.6713 0.5442 1.216 1.216 1.216zm2.363 7.665v-6.744h2.01v0.9222h0.0283c0.2795-0.5294 0.9628-1.089 1.982-1.089 2.123 0 2.514 1.396 2.514 3.213v3.698h-2.095v-3.28c0-0.7817-0.0134-1.788-1.09-1.788-1.091 0-1.257 0.8522-1.257 1.732v3.335zm-1.315-6.744h-2.097v6.744h2.097z" />
  </svg>
);

export default LinkedinIcon;
