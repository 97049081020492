import React, { HTMLProps, forwardRef, ReactNode } from 'react';

interface ISources {
  srcSet: string;
  sizes: string;
  type: string;
}

interface IPicture extends HTMLProps<HTMLPictureElement> {
  src?: string;
  srcSet?: string;
  sources?: ISources[];
  alt?: string;
  className?: string;
  srcSetWebp?: string;
  srcWebp?: string;
  height?: string | number;
  width?: string | number;
  loading?: 'eager' | 'lazy';
  children?: ReactNode;
}

const Picture = forwardRef<HTMLPictureElement, IPicture>(
  ({
    src = '',
    srcSet = '',
    alt = '',
    sizes,
    sources,
    srcWebp,
    height,
    width,
    className = '',
    loading = 'eager',
    children,
    ...res
  }, ref) => (sources?.length ? (
    <picture className={className} ref={ref} {...res}>
      {sources.map((source) => (
        <source
          key={`source_${source.type}}`}
          srcSet={source.srcSet}
          sizes={source.sizes}
          type={source.type}
        />
      ))}
      <source
        srcSet={srcSet || sources[0].srcSet || ''}
        sizes={sizes}
        type="image/jpg"
      />
      <img
        src={src}
        srcSet={srcSet || sources[0].srcSet || ''}
        sizes={sizes}
        alt={alt}
        width={width}
        height={height}
        loading={loading}
      />
      {children}
    </picture>
  ) : (
    <picture className={className} ref={ref} {...res}>
      <img
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        alt={alt}
        width={width}
        height={height}
        loading={loading}
      />
      {children}
    </picture>
  )),
);

export default Picture;
