import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    justify-content: start;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    justify-content: space-between;
  }
`;

export const LinkWrapper = styled.small`
  :hover {
    color: var(--black);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-right: 4em;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-right: 0;
  }
`;
