import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`;

export const Iframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--black);
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ThumbnailWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  pointer-events: auto;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const PlayButtonWrapper = styled.div`
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: grid;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;

  &.active {
    opacity: 0;
    pointer-events: none;
  }
`;

export const PlayButton = styled.div`
  position: absolute;
  top: calc(50% - 1.5em);
  left: calc(50% - 1.5em);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: var(--white);
  z-index: 11;
  cursor: pointer;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 1.15em;
    left: 1.15em;
    grid-row: 1;
    grid-column: 1;
    width: 0;
    height: 0;
    border-top: 0.35em solid transparent;
    border-bottom: 0.35em solid transparent;
    border-left: 0.7em solid var(--black);
  }
`;
