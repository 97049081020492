import React, { FC, ReactNode } from 'react';

import ButtonLink from './styled';

interface IMailButton {
  children: ReactNode;
  href: string;
  title?: string;
  className?: string;
}

const MailButton: FC<IMailButton> = ({
  children,
  href,
  title = '',
  className = '',
  ...props
}) => (
  <ButtonLink className={className} href={href} title={title} {...props}>
    {children}
  </ButtonLink>
);

export default MailButton;
