import React, {
  FC, useState, MouseEvent, useEffect, useRef,
} from 'react';

import {
  Wrapper, Iframe, Image, ThumbnailWrapper, PlayButton, PlayButtonWrapper,
} from './YoutubeVideo.styled';

interface IYoutubeVideo {
  url: string;
  title?: string;
  className?: string;
  active?: boolean;
  onOpen?: () => void;
}

const YoutubeVideo: FC<IYoutubeVideo> = ({
  url, title, className, active = true, onOpen = () => {},
}) => {
  const frameRef = useRef<HTMLIFrameElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const getVideoId = (): string => {
    const regEx = /(?:youtube(?:-nocookie)?\.com\/(?:[^\\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = regEx.exec(url);

    return (match && match[1].length === 11) ? match[1] : '';
  };

  const getVideoThumbnail = (): string => {
    const videoId = getVideoId();

    return `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`;
  };

  const pauseVideo = () => {
    const video = frameRef.current;
    setOpen(false);

    if (video) {
      const params = {
        event: 'command',
        func: 'stopVideo',
        args: '',
      };
      video?.contentWindow.postMessage(JSON.stringify(params), '*');
    }
  };

  const playVideo = () => {
    const video = frameRef.current;

    if (video) {
      const params = {
        event: 'command',
        func: 'playVideo',
        args: '',
      };
      video?.contentWindow.postMessage(JSON.stringify(params), '*');
    }
  };

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    setOpen(true);
    onOpen();
    playVideo();
  };

  useEffect(() => {
    if (!active) {
      pauseVideo();
    }
  }, [active]);

  return (
    <Wrapper className={className}>
      <ThumbnailWrapper className={(open && active) ? 'hidden' : ''} onClick={handleClick}>
        <PlayButtonWrapper>
          <PlayButton />
        </PlayButtonWrapper>
        <Image src={getVideoThumbnail()} alt={title} />
      </ThumbnailWrapper>
      {open && (
        <Iframe
          id="ytplayer"
          allow="autoplay"
          ref={frameRef}
          src={`https://www.youtube.com/embed/${getVideoId()}?autoplay=1&showinfo=0&frameBorder=0&rel=0&enablejsapi=1&origin=https://wayray.com`}
          title={title}
        />
      )}
    </Wrapper>
  );
};

export default YoutubeVideo;
