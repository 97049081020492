import React, { HTMLProps, forwardRef, ReactNode } from 'react';

import { IMediaImage } from 'interfaces/media';
import Picture from 'UI/Picture/Picture';

type IImageElementProps = Omit<HTMLProps<HTMLPictureElement>, 'id'>;
interface IImageElement extends IImageElementProps {
  htmlId?: string;
}

interface IGatsbyPicture extends IImageElement, IMediaImage {
  className?: string;
  children?: ReactNode;
}

const GatsbyPicture = forwardRef<HTMLPictureElement, IGatsbyPicture>(({
  localFile,
  htmlId,
  alternativeText = '',
  className = '',
  children,
}, ref) => {
  const imageData = localFile?.childImageSharp?.gatsbyImageData;
  if (!imageData) return null;

  const { images: { fallback, sources }, width, height } = imageData;

  return (
    <Picture
      src={fallback.src}
      srcSet={fallback.srcSet}
      sizes={fallback.sizes}
      alt={alternativeText}
      sources={sources}
      id={htmlId}
      className={className}
      ref={ref}
      width={`${width}px`}
      height={`${height}px`}
    >
      {children}
    </Picture>
  );
});

export default GatsbyPicture;
