import styled from '@emotion/styled';

import { MAX_MOB_WIDTH } from 'constants/sizes';

import { DownloadIcon as UIDownloadIcon } from '../Icons';

export const IconWrapper = styled.div`
  min-width: 30px;
  min-height: 30px;
  width: 1.5em;
  height: 1.5em;
  background: var(--border-color);
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
  transition: .1s ease-in-out;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 20px;
    height: 20px;
    min-width: auto;
    min-height: auto;
  }
`;

export const Icon = styled(UIDownloadIcon)`
  min-width: 5.7px;
  width: 0.5em;
  height: auto;
  transition: none;
  transition: none;
`;

export const Link = styled.a`
  display: grid;
  gap: 0.57em;
  grid-auto-flow: column;
  align-items: center;
  transition: .1s ease-in-out;

  :hover {
    fill: var(--white);
    color: var(--black-opacity);

    ${IconWrapper} {
      background: var(--black-opacity);
    }
  }
`;
