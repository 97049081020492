exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-cookie-settings-tsx": () => import("./../../../src/pages/cookie-settings.tsx" /* webpackChunkName: "component---src-pages-cookie-settings-tsx" */),
  "component---src-pages-cookies-list-tsx": () => import("./../../../src/pages/cookies-list.tsx" /* webpackChunkName: "component---src-pages-cookies-list-tsx" */),
  "component---src-pages-culture-tsx": () => import("./../../../src/pages/culture.tsx" /* webpackChunkName: "component---src-pages-culture-tsx" */),
  "component---src-pages-deep-reality-display-tsx": () => import("./../../../src/pages/deep-reality-display.tsx" /* webpackChunkName: "component---src-pages-deep-reality-display-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-media-coverage-tsx": () => import("./../../../src/pages/media-coverage.tsx" /* webpackChunkName: "component---src-pages-media-coverage-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-press-area-tsx": () => import("./../../../src/pages/press-area.tsx" /* webpackChunkName: "component---src-pages-press-area-tsx" */),
  "component---src-pages-press-releases-tsx": () => import("./../../../src/pages/press-releases.tsx" /* webpackChunkName: "component---src-pages-press-releases-tsx" */),
  "component---src-pages-quality-policy-tsx": () => import("./../../../src/pages/quality-policy.tsx" /* webpackChunkName: "component---src-pages-quality-policy-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-careers-vacancy-tsx": () => import("./../../../src/templates/careers/vacancy.tsx" /* webpackChunkName: "component---src-templates-careers-vacancy-tsx" */),
  "component---src-templates-events-event-tsx": () => import("./../../../src/templates/events/event.tsx" /* webpackChunkName: "component---src-templates-events-event-tsx" */),
  "component---src-templates-press-releases-release-tsx": () => import("./../../../src/templates/press-releases/release.tsx" /* webpackChunkName: "component---src-templates-press-releases-release-tsx" */)
}

