import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MIN_2K, STANDART_DESKTOP_WIDTH,
} from 'constants/sizes';
import { Layout, Button as UIButton, ButtonLink } from 'UI';

export const Container = styled(Layout)`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--black);
  color: var(--white);
  width: auto;
  align-items: center;
  z-index: 10;
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  left: 160px;

  @media (min-width: ${MIN_2K}px) {
    left: 6.55%;
  }

  @media (min-width: ${STANDART_DESKTOP_WIDTH}px) {
    left: 14%;
  }

  @media (min-width: ${MIN_2K}px) {
    left: 10.9%;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    left: 148px;
    row-gap: 2rem;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    left: 0px;
    row-gap: 2rem;
    padding-top: 1.5rem;
  }
`;

export const Paragraph = styled.small`
  color: var(--white);
  grid-row: 1;
  grid-column: 1/6;

  a {
    color: var(--red);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

const Button = css`
  grid-row: 1;
  height: 3em;
  font-size: 1em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 2;
  }

  :hover {
    background: var(--white);
    color: var(--black);
  }
`;

export const SettingsButton = styled(ButtonLink)`
  ${Button}
  grid-column: 7/9;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/3;
  }
`;

export const AgreeButton = styled(UIButton)`
  ${Button}
  grid-column: 9/11;
  color: var(--white);
  background: var(--red);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 3/5;
  }
`;
