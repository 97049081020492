import React, { ReactNode, FC } from 'react';
import { Link } from 'gatsby';

interface INextLink {
  children: ReactNode;
  className?: string;
  path: string;
  rel?: string;
  target?: string;
  title?: string;
}

const NextLink: FC<INextLink> = ({
  children,
  className = '',
  path = '/',
  rel = '',
  target,
  title,
  ...res
}) => (
  <Link
    className={className}
    rel={rel}
    role="link"
    target={target}
    title={title}
    to={path}
    {...res}
  >
    {children}
  </Link>
);

export default NextLink;
