import React, { FC } from 'react';

import { IIcon } from './interfaces';

const FacebookIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="m4.572 6.359v5.474h2.144v-5.474h2.105l0.0957-1.971h-2.2v-1.439c0-0.5434 0.1171-0.8709 0.848-0.8709 0.6753 0 1.294 0.00586 1.294 0.00586l0.0478-1.841s-0.6068-0.07647-1.427-0.07647c-2.027 0-2.906 1.215-2.906 2.538v1.684h-1.489v1.971z" />
  </svg>
);

export default FacebookIcon;
