import styled from '@emotion/styled';

interface IVideoElement {
  type: string;
}

// eslint-disable-next-line import/prefer-default-export
export const VideoElement = styled.video<IVideoElement>`
  width: 100%;
`;
