import React, {
  FC, ReactNode, useEffect, useRef,
} from 'react';

import throttle from 'utils/throttle';

import { Container, Wrapper } from './styled';

interface IScaleVisible {
  children: ReactNode;
}

const ScaleVisible: FC<IScaleVisible> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  const handleCheckScale = () => {
    const height = window.innerHeight;

    if (!containerRef.current) return;

    const { top = 0, bottom = 0 } = wrapperRef.current?.getBoundingClientRect() || {};

    if (height < top || bottom < 0) return;

    let multiplier = 1;

    if (top > 0) multiplier = 1 - top / height;
    else if (bottom < height) multiplier = bottom / height;

    const scale = multiplier < 0.8 ? 0.9 + 0.12 * multiplier : 1;

    containerRef.current.style.transform = `scale(${scale})`;
  };

  const handleScroll = () => {
    throttle(handleCheckScale, 20)();
  };

  const handler = ({ visibility }) => {
    if (visibility) {
      document.removeEventListener('scroll', handleScroll);
      document.addEventListener('scroll', handleScroll, true);
    } else {
      document.removeEventListener('scroll', handleScroll);
    }
  };

  const handleAppear = ({ visibility }) => {
    if (visibility) {
      wrapperRef.current?.classList?.add('visible');
    } else {
      wrapperRef.current?.classList?.remove('visible');
    }
  };

  useEffect(() => {
    handleCheckScale();

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Container
      ref={wrapperRef}
      way={handleAppear}
      options={{
        threshold: 0.9,
        rootMargin: '0px 0px 0px',
      }}
    >
      <Wrapper
        ref={containerRef}
        style={{ transform: 'scale(0.9)' }}
        way={handler}
        options={{
          threshold: 0.0,
          rootMargin: '100% 0px 0px',
        }}
      >
        {children}
      </Wrapper>
    </Container>
  );
};

export default ScaleVisible;
