import React, { FC } from 'react';

import { IIcon } from './interfaces';

const FullLogoIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 96 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M70.4727 10.5638C70.6681 10.4485 70.9208 10.1763 71.0713 9.91834C71.2219 9.65263 71.2991 9.34803 71.2991 9.00195C71.2991 8.53532 71.1522 8.1698 70.856 7.9015C70.5623 7.63708 70.1503 7.50098 69.6276 7.50098H67.0388V13.2262H67.7719V10.7842H69.5604C69.5903 10.7842 69.6363 10.779 69.6836 10.7751L70.5884 13.2379H71.3464L70.3607 10.6105C70.3943 10.5962 70.4428 10.5807 70.4727 10.5638ZM67.7706 10.1413V8.14518H69.589C69.9325 8.14518 70.1814 8.23461 70.3345 8.41608C70.4889 8.59884 70.566 8.83474 70.566 9.1212C70.566 9.33118 70.5324 9.50227 70.4627 9.63189C70.393 9.7641 70.3009 9.86779 70.1839 9.94427C70.0707 10.022 69.935 10.0726 69.7807 10.1024C69.6264 10.127 69.467 10.1413 69.2953 10.1413H67.7706Z"
      fill="#FF1E31"
    />
    <path
      d="M32.5828 13.2281L31.4813 8.91317H31.4626L30.3612 13.2281H29.3729L27.9043 7.50293H28.6436L29.867 12.2897H29.8807L31.1029 7.50293H31.8435L33.0607 12.2897H33.0794L34.2954 7.50293H35.0322L33.5635 13.2281H32.5828Z"
      fill="#FF1E31"
    />
    <path
      d="M57.5923 13.2272H56.8617V10.9148L54.7446 7.50195H55.5623L57.2127 10.1643L58.8605 7.50195H59.6895L57.591 10.885V13.2272H57.5923Z"
      fill="#FF1E31"
    />
    <path
      d="M93.9035 13.2272H93.1728V10.9148L91.054 7.50195H91.8719L93.5226 10.1643L95.1709 7.50195H96L93.9011 10.885V13.2272H93.9035Z"
      fill="#FF1E31"
    />
    <path
      d="M43.2295 12.9602L42.3809 13.2272L44.5186 7.50195H45.2577L47.3979 13.2272L46.5567 12.9602L44.8943 8.50001H44.8806L43.2295 12.9602Z"
      fill="#FF1E31"
    />
    <path
      d="M79.541 12.9602L78.6921 13.2272L80.8304 7.50195H81.5697L83.7104 13.2272L82.8691 12.9602L81.2062 8.50001H81.1926L79.541 12.9602Z"
      fill="#FF1E31"
    />
    <path d="M8.02026 15.418L0 18L8.02026 0V15.418Z" fill="#FF1E31" />
    <path d="M10.4868 15.418L18.5058 18L10.4868 0V15.418Z" fill="#FF1E31" />
  </svg>
);

export default FullLogoIcon;
